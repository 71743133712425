import type { IconsKeys } from './types';

import React from 'react';

import { icons } from './constants';

export type IconProps = {
  id: IconsKeys;
  attr?: {
    type?: string;
    width: number;
    height: number;
    viewBox?: string;
  };
  className?: string;
};

const Icon = ({ id, attr, className }: IconProps) =>
  React.createElement(icons[id], {
    className: `ui-icon ${className || ''}`,
    ...attr,
  });

export default Icon;
