import type React from 'react';
import type { IconsProps } from '../types';

const OfficialStores = ({
  className = '',
  width = 320,
  height = 132,
  viewBox = '0 0 320 132',
}: Partial<IconsProps>) => (
  <svg
    width={width}
    height={height}
    viewBox={viewBox}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    data-testid="icon-error"
  >
    <g>
      <g>
        <g>
          <g id="Beans">
            <path
              id="&#240;&#159;&#142;&#168; Color"
              fillRule="evenodd"
              clipRule="evenodd"
              d="M129.545 131.257C113.975 125.154 96.9948 113.834 87.0407 98.921C72.5752 77.2494 73.199 60.661 78.6529 43.4485C84.1637 26.0499 102.103 7.53113 126.974 16.209C151.845 24.8868 154.537 37.8317 186.125 37.8317C217.712 37.8317 236.082 50.9895 240.514 69.4971C244.946 88.0047 237.137 106.753 226.759 115.558C218.711 122.386 210.41 127.899 200.085 131.61L129.545 131.257Z"
              fill="black"
              fillOpacity="0.04"
            />
          </g>
          <g id="Group 3034">
            <g id="Group 3017">
              <g id="Group 3016">
                <g id="Group 3014">
                  <rect
                    id="Rectangle 415"
                    x="231.504"
                    y="87.0137"
                    width="114.15"
                    height="19.8296"
                    rx="2"
                    transform="rotate(-180 231.504 87.0137)"
                    fill="white"
                  />
                  <path
                    id="Intersect"
                    d="M201.579 85.5137L208.965 68.6841L228.737 68.6841L221.351 85.5137L201.579 85.5137ZM182.641 68.6841L175.255 85.5137L155.099 85.5137L162.485 68.6841L182.641 68.6841ZM120.088 68.6841L136.161 68.6841L128.775 85.5137L120.088 85.5137C119.407 85.5137 118.854 84.9612 118.854 84.2785L118.854 69.9192C118.854 69.2365 119.407 68.6841 120.088 68.6841Z"
                    fill="#EEEEEE"
                    stroke="white"
                    strokeWidth="3"
                  />
                  <path
                    id="Intersect_2"
                    d="M199.283 87.0137L207.985 67.1841L228.77 67.1841C229.517 67.1841 230.195 67.484 230.688 67.9702L222.331 87.0137M176.235 87.0137L184.937 67.1841L161.505 67.1841L152.803 87.0137L176.235 87.0137ZM138.457 67.1841L129.755 87.0137L120.088 87.0137C118.578 87.0137 117.354 85.7891 117.354 84.2785L117.354 69.9192C117.354 68.4086 118.578 67.1841 120.088 67.1841L138.457 67.1841Z"
                    stroke="#333333"
                    strokeWidth="1.5"
                  />
                  <path
                    id="Rectangle 416"
                    d="M203.587 87.0137L119.354 87.0137C118.25 87.0137 117.354 86.1182 117.354 85.0137L117.354 69.1841C117.354 68.0795 118.25 67.1841 119.354 67.1841L229.504 67.1841C230.609 67.1841 231.504 68.0795 231.504 69.1841L231.504 85.0137C231.504 86.1182 230.609 87.0137 229.504 87.0137L218.369 87.0137M208.97 87.0137L212.986 87.0137"
                    stroke="#333333"
                    strokeWidth="1.5"
                  />
                </g>
              </g>
              <rect
                id="Rectangle 413"
                x="114.753"
                y="52.7852"
                width="32.8855"
                height="71.8738"
                rx="5.5"
                fill="#EEEEEE"
                stroke="white"
                strokeWidth="3"
              />
              <path
                id="Rectangle 415_2"
                d="M113.253 101.952V58.2852C113.253 54.4192 116.387 51.2852 120.253 51.2852H142.139C146.005 51.2852 149.139 54.4192 149.139 58.2852V119.159C149.139 123.025 146.005 126.159 142.139 126.159H120.253C116.387 126.159 113.253 123.025 113.253 119.159V107.252"
                stroke="#333333"
                strokeWidth="1.5"
              />
              <g id="Rectangle 414">
                <mask id="path-8-inside-1_4701_28687" fill="white">
                  <rect
                    x="106.076"
                    y="113.51"
                    width="50.2396"
                    height="16.0688"
                    rx="2"
                  />
                </mask>
                <rect
                  x="106.076"
                  y="113.51"
                  width="50.2396"
                  height="16.0688"
                  rx="2"
                  fill="white"
                  stroke="white"
                  strokeWidth="6"
                  mask="url(#path-8-inside-1_4701_28687)"
                />
              </g>
              <rect
                id="Rectangle 416_2"
                x="106.076"
                y="113.51"
                width="50.2396"
                height="16.0688"
                rx="2"
                stroke="#333333"
                strokeWidth="1.5"
              />
              <path
                id="Rectangle 417"
                d="M245 129.578H94.6229"
                stroke="#333333"
                strokeWidth="1.5"
              />
              <ellipse
                id="Ellipse 205"
                cx="131.196"
                cy="77.0987"
                rx="7.34797"
                ry="7.35063"
                fill="white"
                stroke="#333333"
                strokeWidth="1.5"
              />
              <g id="Group 93509">
                <ellipse
                  id="Ellipse 206"
                  cx="1.36707"
                  cy="1.36756"
                  rx="1.36707"
                  ry="1.36756"
                  transform="matrix(1 0 0 -1 137.86 101.885)"
                  fill="#333333"
                />
                <ellipse
                  id="Ellipse 208"
                  cx="1.36707"
                  cy="1.36756"
                  rx="1.36707"
                  ry="1.36756"
                  transform="matrix(1 0 0 -1 121.797 101.885)"
                  fill="#333333"
                />
              </g>
            </g>
          </g>
          <g id="Feedback_Warning">
            <g id="Triangle">
              <path
                id="&#240;&#159;&#142;&#168; Color_2"
                d="M185.635 17.2605C186.99 15.173 190.046 15.173 191.401 17.2605L212.137 49.2063C213.621 51.4928 211.98 54.5144 209.254 54.5144H167.781C165.055 54.5144 163.414 51.4928 164.899 49.2063L185.635 17.2605Z"
                fill="white"
                stroke="white"
                strokeWidth="3"
              />
              <path
                id="Triangle_2"
                fillRule="evenodd"
                clipRule="evenodd"
                d="M184.377 16.4438C186.323 13.4453 190.712 13.4453 192.659 16.4438L213.395 48.3896C215.527 51.674 213.17 56.0144 209.254 56.0144H167.781C163.866 56.0144 161.508 51.6741 163.64 48.3896L184.377 16.4438Z"
                stroke="#333333"
                strokeWidth="1.5"
              />
            </g>
            <g id="Icon shape">
              <path
                d="M190.706 28.6641H186.331L186.878 40.6986H190.159L190.706 28.6641Z"
                fill="#333333"
              />
              <path
                d="M188.518 42.8867C189.726 42.8867 190.706 43.8663 190.706 45.0748C190.706 46.2832 189.726 47.2629 188.518 47.2629C187.31 47.2629 186.331 46.2832 186.331 45.0748C186.331 43.8663 187.31 42.8867 188.518 42.8867Z"
                fill="#333333"
              />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default OfficialStores;
