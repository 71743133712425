import type React from 'react';
import type { IconsProps } from '../types';

const SecurityPayment = ({
  className = '',
  width = 17,
  height = 16,
  viewBox = '0 0 17 16',
}: Partial<IconsProps>) => (
  <svg
    width={width}
    height={height}
    viewBox={viewBox}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    data-testid="icon-security-payment"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.89899 3.89024C1.89899 9.52302 4.06377 13.1325 8.37225 14.5687L8.56198 14.6319L8.75172
      14.5687C13.0602 13.1325 15.225 9.52302 15.225 3.89024V3.29024H14.625C12.7549 3.29024 10.8647
      2.507 8.94484 0.915925L8.56198 0.598633L8.17913 0.915925C6.25926 2.507 4.36909 3.29024 2.49899
      3.29024H1.89899V3.89024ZM8.56198 2.1475C10.3614 3.54798 12.1816 4.32609 14.0169 4.46692C13.8825
      9.1917 12.0701 12.1155 8.56198 13.3648C5.05389 12.1155 3.24152 9.1917 3.10707 4.46692C4.94234
      4.32609 6.7626 3.54798 8.56198 2.1475ZM6.08322 7.11086L7.65737 8.624L10.848 5.04176L11.7441
      5.83989L7.72442 10.3529L5.25162 7.97599L6.08322 7.11086Z"
      fill="black"
      fillOpacity="0.8"
    />
  </svg>
);

export default SecurityPayment;
